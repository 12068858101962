<template>
  <div id="data-analytic-dashboard" class="site-container">
    <div v-if="isLoading" class="loading-screen">
      <div class="loading-container">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <template v-else>
      <div v-if="dataSavingBarCharts" class="wp-data-analytic">
        <div class="data-analytic-general">
          <div class="col-12">
            <div class="row height-data-saving">
              <div class="col-4 customize-col-4 space--right">
                <div class="site-information-form">
                  <div class="site-information-content">
                    <div class="site-information-detail">
                      <div class="detail-desc">
                        <div class="row-info-space">
                          <p v-if="dataSavingBarCharts?.site" class="project-address">{{ dataSavingBarCharts.site.global }} / {{ dataSavingBarCharts.site.regional }} / {{ dataSavingBarCharts.site.area }} / {{ dataSavingBarCharts.site.project }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">SITE ID</p>
                          <p class="detail-desc-content">{{ dataSavingBarCharts.site_id ? dataSavingBarCharts.site_id : '' }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">SITE NAME</p>
                          <p class="detail-desc-content">{{ dataSavingBarCharts.site_name ? dataSavingBarCharts.site_name : '' }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">COMMISSIONED DATE</p>
                          <p class="detail-desc-content">{{ dataSavingBarCharts.commissioned_date ? MOMENT(dataSavingBarCharts.commissioned_date, "DD/MM/YYYY") : '' }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="menu-filters-wp">
                  <div class="date-type-filters">
                    <v-btn-toggle v-model="selectedFilterDateType" @change="updateFilterDateType" mandatory>
                      <v-btn value="day">DAY</v-btn>
                      <v-btn value="month">MONTH</v-btn>
                      <v-btn value="year">YEAR</v-btn>
                      <v-btn value="life_time">LIFETIME</v-btn>
                    </v-btn-toggle>
                  </div>
                  <div class="pick-date-filters">
                    <!-- DAY PICKER -->
                    <template v-if="['day'].includes(selectedFilterDateType)">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-top="0"
                        :nudge-right="170"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-row no-gutters class="date-picker-controls">
                            <v-btn icon class="prev-button" @click="prevDay">
                              <i class="fas fa-chevron-left"></i>
                            </v-btn>
                            <v-text-field
                              v-model="selectedDateText"
                              append-icon="fas fa-caret-down"
                              placeholder="Pick Date"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="select-date-analytic"
                            ></v-text-field>
                            <v-btn icon class="next-button" @click="nextDay" :disabled="disabledNextButton(selectedDate, 'YYYY-MM-DD')" :class="`${disabledNextButton(selectedDate, 'YYYY-MM-DD') ? 'can-not-click' : ''}`">
                              <i class="fas fa-chevron-right"></i>
                            </v-btn>
                          </v-row>
                        </template>
                        <v-date-picker
                          v-model="selectedDate"
                          :max="NOW_DATE().format(DATE_PICKER_FORMAT)"
                          no-title
                          type="date"
                          @change="reloadBarCharts('date')"
                          class="date-analytic-table"
                        >
                        </v-date-picker>
                      </v-menu>
                    </template>
                    <!-- MONTH PICKER -->
                    <template v-if="['month'].includes(selectedFilterDateType)">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-top="0"
                        :nudge-right="170"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-row no-gutters class="date-picker-controls">
                            <v-btn icon class="prev-button" @click="prevMonth">
                              <i class="fas fa-chevron-left"></i>
                            </v-btn>
                            <v-text-field
                              v-model="selectedMonthText"
                              append-icon="fas fa-caret-down"
                              placeholder="Pick Month"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="select-date-analytic select-month-width"
                            ></v-text-field>
                            <v-btn icon class="next-button" @click="nextMonth" :disabled="disabledNextButton(selectedMonth, 'YYYY-MM')" :class="`${disabledNextButton(selectedMonth, 'YYYY-MM') ? 'can-not-click' : ''}`">
                              <i class="fas fa-chevron-right"></i>
                            </v-btn>
                          </v-row>
                        </template>
                        <v-date-picker
                          v-model="selectedMonth"
                          :max="NOW_DATE().format(DATE_PICKER_FORMAT)"
                          no-title
                          type="month"
                          @change="reloadBarCharts('month')"
                          class="date-analytic-table"
                        >
                        </v-date-picker>
                      </v-menu>
                    </template>
                    <!-- YEAR PICKER -->
                    <template v-if="['year'].includes(selectedFilterDateType)">
                      <v-row no-gutters class="date-picker-controls">
                        <v-btn icon class="prev-button" @click="prevYear" :disabled="selectedYear <= 2020" :class="`${selectedYear <= 2020 ? 'can-not-click' : ''}`">
                          <i class="fas fa-chevron-left"></i>
                        </v-btn>
                        <v-select
                          :items="yearsList"
                          v-model="selectedYear"
                          filled
                          :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_menu_year custom_active_item' }"
                          append-icon="fas fa-caret-down"
                          class="custom-select-year"
                          @change="reloadBarCharts"
                        ></v-select>
                        <v-btn icon class="next-button" @click="nextYear" :disabled="disabledNextButton(selectedYear, 'YYYY')" :class="`${disabledNextButton(selectedYear, 'YYYY') ? 'can-not-click' : ''}`">
                          <i class="fas fa-chevron-right"></i>
                        </v-btn>
                      </v-row>
                    </template>
                    <!-- LIFETIME PICKER -->
                    <template v-if="['life_time'].includes(selectedFilterDateType)">
                      <v-row no-gutters class="date-picker-controls" style="pointer-events: none;">
                        <v-btn icon class="prev-button can-not-click">
                          <i class="fas fa-chevron-left"></i>
                        </v-btn>
                        <v-text-field
                          v-model="selectedLifeTimeText"
                          append-icon="fas fa-caret-down"
                          placeholder="Pick LifeTime"
                          readonly
                          class="select-date-analytic select-lifetime-width"
                        ></v-text-field>
                        <v-btn icon class="next-button can-not-click">
                          <i class="fas fa-chevron-right"></i>
                        </v-btn>
                      </v-row>
                    </template>
                  </div>
                </div>
                <div class="total-sites-chart total-sites-alarm">
                  <div class="total-sites-chart-heading chart_heading_bg pt-b_Saving">
                    <h2 class="total-sites-chart--title chart--title_size">ELECTRICITY TREND</h2>
                    <div class="info-data info-data-green">
                      <template v-if="dataSavingBarCharts?.summary?.electricity_trend !== null && dataSavingBarCharts?.summary?.electricity_percent !== null">
                        <span class="down-arrow">
                          <img v-if="dataSavingBarCharts.summary.electricity_trend === 'increment'" src="@/assets/pages/single-site/arrow-up-red.svg" />
                          <img v-else src="@/assets/pages/single-site/arrow-down-green.svg" />
                        </span>
                        <span
                          :style="`color: ${dataSavingBarCharts.summary.electricity_trend === 'increment' ? '#FF453A' : '#4A8521'}`" 
                          class="info-num"
                        >
                          {{ formatDecimalNumber(dataSavingBarCharts.summary.electricity_percent) }}%
                        </span>
                      </template>
                      <span v-else class="info-num text-light">N/A</span>
                    </div>
                    </div>
                  <div class="col-12 total-sites-chart-content total-sites-chart-content_space position-relative">
                    <div class="bar-chart bar_chart_margin position-absolute electricity_percent h-100 w-100">
                      <VueApexCharts
                        type="line"
                        :options="electricityTrendOptions" 
                        :series="electricityTrendSeries"
                        :height="electricityHeight"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8 customize-col-8 space--left d-flex flex-column justify-content-between">
                <div class="bar-chart">
                  <div class="bar-chart-heading">
                    <h2 class="bar-chart-heading--title">ECONOMIC IMPACT</h2>
                    <div class="total_info_wp">
                      <div class="total_info_desc">
                        <p>Total Expense</p>
                        <strong class="info-num">{{ dataSavingBarCharts?.summary?.total_expense ? convertBahtCurrency(dataSavingBarCharts.summary.total_expense) : '0' }}</strong>
                        <span class="info-unit-more">{{ dataSavingBarCharts?.summary?.total_expense ? convertCountingUnit(dataSavingBarCharts.summary.total_expense) : '' }}</span>
                        <span class="currency_symbol">
                          {{ "\u0e3f" }}
                        </span>
                      </div>
                      <div class="total_info_desc">
                        <p>Total Saving</p>
                        <strong class="info-num">{{ dataSavingBarCharts?.summary?.today_saving ? convertBahtCurrency(dataSavingBarCharts.summary.today_saving) : '0' }}</strong>
                        <span class="info-unit-more">{{ dataSavingBarCharts?.summary?.today_saving ? convertCountingUnit(dataSavingBarCharts.summary.today_saving) : '' }}</span>
                        <span class="currency_symbol">
                          {{ "\u0e3f" }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <VueApexCharts
                    type="bar"
                    :height="chartHeight" 
                    :options="economicImpactOptions" 
                    :series="economicImpactSeries"
                  />
                </div>
                <div class="bar-chart">
                  <div class="bar-chart-heading">
                    <h2 class="bar-chart-heading--title">ENVIRONMENT IMPACT</h2>
                    <div class="total_info_wp">
                      <div class="total_info_desc">
                        <p>Total CO2 Saving</p>
                        <strong>{{ dataSavingBarCharts?.summary?.today_co2_saving ? convertCo2Value(dataSavingBarCharts.summary.today_co2_saving) : '0' }}</strong>
                        <span class="total_info_unit">{{ dataSavingBarCharts?.summary?.today_co2_saving ? convertCo2Unit(dataSavingBarCharts.summary.today_co2_saving) : 'kg' }}</span>
                      </div>
                    </div>
                  </div>
                  <VueApexCharts
                    type="bar"
                    :height="chartHeight" 
                    :options="environmentImpactOptions" 
                    :series="environmentImpactSeries"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="@/assets/css/data-analytic-dashboard.scss"></style>

<script>
import VueApexCharts from "vue-apexcharts";
import { dataSavingService, } from "@/services";
import moment from "moment";

export default {
  name: "DataSavingDashboard",
  components: { VueApexCharts, },
  data() {
    return {
      isLoading: true,
      selectedFilterDateType: localStorage.getItem('filter_date_type_localstorage') || 'day',
      selectedDate: moment().format("YYYY-MM-DD"),
      selectedMonth: moment().format("YYYY-MM"),
      selectedYear: moment().format("YYYY"),
      yearsList: [],
      economicImpactOptions: {},
      environmentImpactOptions: {},
      electricityTrendOptions: {},
      dataSavingBarCharts: null,
      economicImpactSeries: [],
      xaxisEconomicSeriesLabel: [],
      xaxisEnvironmentSeriesLabel: [],
      xaxisElectricitySeriesLabel: [],
      environmentImpactSeries: [],
      electricityTrendSeries: [],
      chartColorsList: {
        "cost_and_saving": ['#FF0000', '#0070C0'], 
        "co2_saving": ['#00B050'],
        "electricity": ['#ffffffe6'],
      },
      chartHeight: this.calculateChartHeight(),
      electricityHeight: this.calculateElectricityHeight(),
      selectedDateText: moment().format("DD/MM/YYYY"),
      selectedMonthText: moment().format("MM/YYYY"),
      selectedLifeTimeText: "SINCE " + moment().format("DD/MM/YYYY"),
      chartXNames: {
        "saving": "Saving", 
        "cost": "Expense", 
        "co2_saving": "CO2 Saving (kg)",
        "electricity": "Usage (kWh)",
      },
      barDefaultOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: true,
          borderColor: '#2C2C2E'
        },
        toolbar: {
          download: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          type: 'solid',
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: '#8E8E93',
            },
          },
          crosshairs: {
            fill: {
              gradient: {
                opacityFrom: 0,
                opacityTo: 0,
              }
            }
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          },
          showForSingleSeries: true,
          fontSize: '14px',
          labels: {
            colors: '#8E8E93',
          },
          markers: {
            width: 14,
            height: 14,
          },
        },
        markers: {
          size: [4, 4]
        }
      },
      formatDefaultValues: {
        yaxis: {
          labels: {
            style: {
              colors: ["#8E8E93"],
            },
            formatter: function(val) {
              if (val !== null && val !== Infinity) {
                  if(Number.isInteger(val)) {
                    return val;
                  }
              } else {
                  return;
              }
            }
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
          y: {
            formatter: function (val) {
              if (val !== null && val !== Infinity) {
                  return val.toString();
              } else {
                  return;
              }
            }
          }
        },
      },
      formatMoneyValues: {
        yaxis: {
          labels: {
            style: {
              colors: ["#8E8E93"],
            },
            formatter: function(val) {
              if (val !== null && val !== Infinity) {
                if (Number.isInteger(val)) {
                  return val.toLocaleString();
                }
                if (typeof val === 'number') {
                  return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
                }
                if (typeof val === 'string') {
                  return parseFloat(val).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
                }
              } else {
                return;
              }
            }
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
          y: {
            formatter: function(val) {
              if (val !== null && val !== Infinity) {
                if (Number.isInteger(val)) {
                  return val.toLocaleString();
                }
                if (typeof val === 'number') {
                  return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
                }
                if (typeof val === 'string') {
                  return parseFloat(val).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
                }
              } else {
                return;
              }
            }
          }
        },
      },
    };
  },
  async created() {
    await this.reloadBarCharts();
    this.selectedLifeTimeText = this.dataSavingBarCharts?.commissioned_date ? "SINCE " + moment(this.dataSavingBarCharts.commissioned_date).format('DD/MM/YYYY') : this.selectedLifeTimeText;
  },
  mounted() {
    this.yearsList = this.generateYearsList(2020);
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    calculateChartHeight() {
      if (window.innerWidth >= 2048) {
        return 500;
      } else if (window.innerWidth <= 1194) {
        return 240;
      } else if (window.innerWidth <= 1366) {
        return 200;
      } else if (window.innerWidth <= 1920 && window.innerWidth > 1440) {
          return 360;
      } else if (window.innerWidth <= 1440) {
        if (window.innerHeight == 900) {
          return 280;
        } else {
          return 360;
        }
      } else {
        return 200;
      }
    },
    calculateElectricityHeight(){
      switch (true) {
        case (window.innerWidth >= 2048):
          return 740;
        case (window.innerWidth > 1920):
          return 620;
        case (window.innerWidth <= 1440 && window.innerWidth > 1366):
          if (window.innerHeight == 900) {
          return 480;
          } else {
            return 643;
          }
        case (window.innerWidth <= 1920 && window.innerWidth > 1860):
        return 610;
        case (window.innerWidth <= 1366):
          return 370;
        default:
          return 540;
      }
    },
    handleResize() {
      this.chartHeight = this.calculateChartHeight();
    },
    disabledNextButton(time, format) {
      return moment().format(format) <= moment(time).format(format);
    },
    generateYearsList(startYear) {
      const years = [];
      const startMoment = moment({ year: startYear });
      const endMoment = moment();
      while (endMoment.isSameOrAfter(startMoment)) {
        years.push(endMoment.format("YYYY"));
        endMoment.subtract(1, 'years');
      }
      return years;
    },
    prevDay() {
      if(this.selectedDate && this.selectedFilterDateType === 'day') {
        this.selectedDate = moment(this.selectedDate).subtract(1, 'days').format("YYYY-MM-DD");
      }
      this.reloadBarCharts("date");
    },
    nextDay() {
      if(this.selectedDate && this.selectedFilterDateType === 'day') {
        this.selectedDate = moment(this.selectedDate).add(1, 'days').format("YYYY-MM-DD");
      }
      this.reloadBarCharts("date");
    },
    prevMonth() {
      if(this.selectedMonth && this.selectedFilterDateType === 'month') {
        this.selectedMonth = moment(this.selectedMonth).subtract(1, 'months').format('YYYY-MM');
      }
      this.reloadBarCharts("month");
    },
    nextMonth() {
      if(this.selectedMonth && this.selectedFilterDateType === 'month') {
        this.selectedMonth = moment(this.selectedMonth).add(1, 'months').format('YYYY-MM');
      }
      this.reloadBarCharts("month");
    },
    prevYear() {
      if(this.selectedYear && this.selectedFilterDateType === 'year') {
        this.selectedYear = moment(this.selectedYear).subtract(1, 'years').format('YYYY');
      }
      this.reloadBarCharts();
    },
    nextYear() {
      if(this.selectedYear && this.selectedFilterDateType === 'year') {
        this.selectedYear = moment(this.selectedYear).add(1, 'years').format('YYYY');
      }
      this.reloadBarCharts();
    },
    async reloadBarCharts(option) {
      this.isLoading = true;
      if (option === "date") {
        this.selectedDateText = moment(this.selectedDate).format("DD/MM/YYYY");
        // Update for month and year:
        this.selectedMonth = moment(this.selectedDate).format("YYYY-MM");
        this.selectedMonthText = moment(this.selectedDate).format("MM/YYYY");
        this.selectedYear = moment(this.selectedDate).format('YYYY') >= 2020 ? moment(this.selectedDate).format('YYYY') : moment().format('YYYY');
      }
      if (option === "month") {
        this.selectedMonthText = moment(this.selectedMonth).format("MM/YYYY");
        // Update for year:
        this.selectedYear = moment(this.selectedMonth).format('YYYY') >= 2020 ? moment(this.selectedMonth).format('YYYY') : moment().format('YYYY');
      }
      this.resetBarCharts();
      if(this.selectedYear && this.selectedFilterDateType === 'year') {
        this.selectedYear = moment(this.selectedYear).format('YYYY');
      }
      let startEndData = await this.getDateByDateType();
      let data = {
        start_time: startEndData.start_date,
        end_time: startEndData.end_date,
        site_id: this.$store.state.siteId ? this.$store.state.siteId : null,
      };
      await dataSavingService
        .getDataSaving(data)
        .then((res) => {
          if (res.status == 200 && res?.data?.data) {
            this.dataSavingBarCharts = res.data.data;
          } else {
            throw res;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.drawBarCharts();
      this.isLoading = false;
    },
    drawBarCharts() {
      let defaultOptions = {...this.barDefaultOptions, ...this.formatDefaultValues};
      let economicDefaultOptions = {...this.barDefaultOptions, ...this.formatMoneyValues};

      this.economicImpactOptions = { ...economicDefaultOptions, colors: this.chartColorsList["cost_and_saving"] };
      this.environmentImpactOptions = { ...defaultOptions, colors: this.chartColorsList["co2_saving"] };
      this.electricityTrendOptions = { ...defaultOptions, colors: this.chartColorsList["electricity"] };

      this.dataSavingBarCharts.graphs.forEach(chart => {
        const isEconomicChart = chart.key === 'saving' || chart.key === 'cost';
        const isEnvironmentChart = chart.key === 'co2_saving';
        const isElectricityChart = chart.key === 'electricity';

        if (isEconomicChart || isEnvironmentChart || isElectricityChart) {
          const seriesData = chart.value.map(item => item.value);
          const seriesKey = chart.value.map(item =>
            this.selectedFilterDateType === 'day' ? item.key + ':00' : item.key
          );
          const series = {
            name: this.chartXNames[chart.key],
            data: seriesData,
          };
          if (isEconomicChart) {
            this.economicImpactSeries.unshift(series);
            this.xaxisEconomicSeriesLabel.unshift(seriesKey);
          }          
          if (isEnvironmentChart) {
            this.environmentImpactSeries.push(series);
            this.xaxisEnvironmentSeriesLabel.push(seriesKey);
          }
          if (isElectricityChart) {
            this.electricityTrendSeries.push(series);
            this.xaxisElectricitySeriesLabel.push(seriesKey);
          }
        }
      });

      if (["month"].includes(this.selectedFilterDateType)) {
        this.economicImpactOptions.xaxis.categories = this.xaxisEconomicSeriesLabel?.length ? this.xaxisEconomicSeriesLabel[0].map(date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')) : [];
        this.environmentImpactOptions.xaxis.categories = this.xaxisEnvironmentSeriesLabel?.length ? this.xaxisEnvironmentSeriesLabel[0].map(date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')) : [];
        this.electricityTrendOptions.xaxis.categories = this.xaxisElectricitySeriesLabel?.length ? this.xaxisElectricitySeriesLabel[0].map(date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')) : [];
      } else if (["year"].includes(this.selectedFilterDateType)) {
        this.economicImpactOptions.xaxis.categories = this.xaxisEconomicSeriesLabel?.length ? this.xaxisEconomicSeriesLabel[0].map(date => moment(date, 'YYYY-MM').format('MM/YYYY')) : [];
        this.environmentImpactOptions.xaxis.categories = this.xaxisEnvironmentSeriesLabel?.length ? this.xaxisEnvironmentSeriesLabel[0].map(date => moment(date, 'YYYY-MM').format('MM/YYYY')) : [];
        this.electricityTrendOptions.xaxis.categories = this.xaxisElectricitySeriesLabel?.length ? this.xaxisElectricitySeriesLabel[0].map(date => moment(date, 'YYYY-MM').format('MM/YYYY')) : [];
      } else {
        this.economicImpactOptions.xaxis.categories = this.xaxisEconomicSeriesLabel?.length ? this.xaxisEconomicSeriesLabel[0] : [];
        this.environmentImpactOptions.xaxis.categories = this.xaxisEnvironmentSeriesLabel?.length ? this.xaxisEnvironmentSeriesLabel[0] : [];
        this.electricityTrendOptions.xaxis.categories = this.xaxisElectricitySeriesLabel?.length ? this.xaxisElectricitySeriesLabel[0] : [];
      }
    },
    updateFilterDateType() {
      localStorage.setItem('filter_date_type_localstorage', this.selectedFilterDateType);
      this.reloadBarCharts();
    },
    getDateByDateType() {
      let dateType = this.selectedFilterDateType;
      let dateTimes = null;
      if(dateType === "day") {
        dateTimes = {
          start_date: moment(this.selectedDate).startOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          end_date: moment(this.selectedDate).endOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
        };
      } else if(dateType === "month") {
        dateTimes = {
          start_date: moment(this.selectedMonth).startOf("month").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          end_date: moment(this.selectedMonth).endOf("month").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
        }
      } else if (dateType === "year") {
        dateTimes = {
          start_date: moment(this.selectedYear).startOf("year").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          end_date: moment(this.selectedYear).endOf("year").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
        }
      } else {
        dateTimes = {
          start_date: moment("2000-01-01").startOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          end_date: moment().endOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
        }
      }
      return dateTimes;
    },
    resetBarCharts() {
      this.economicImpactSeries = [];
      this.environmentImpactSeries = [];
      this.electricityTrendSeries = [];
      this.economicImpactOptions = {};
      this.environmentImpactOptions = {};
      this.electricityTrendOptions = {};
      this.xaxisEconomicSeriesLabel = [];
      this.xaxisEnvironmentSeriesLabel = [];
      this.xaxisElectricitySeriesLabel = [];
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
